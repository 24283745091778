import { t } from '@common/hooks/use-t.hook';
import { TErrorCodes } from '@src/api/error-codes.type';

type TFECustomErrors = 'Custom_CustomerExpiredCard';
export const GET_ERROR_CODE_NAME = (name: TErrorCodes | TFECustomErrors): string => name;
export const ERROR_CODES_MAP: Record<TErrorCodes | TFECustomErrors, string> = {
  '': t(''),
  undefined: 'Unknown error',

  Custom_CustomerExpiredCard: t('Missing payment details: You need to add a payment method'),
  Forbidden: t("Don't have permission"),
  InvalidLogInParams: t('Something went wrong. Please check and try again.'),
  InvalidRequestParams: t('Something went wrong. Please check and try again.'),
  InvalidSignUpParams: t('Something went wrong. Please check and try again.'),

  InvalidEmail: t('Invalid email'),
  InvalidDeliveryAddress: t('Invalid delivery address'),
  PostCodeNotServed: t('Post code not served'),
  InvalidPostCode: t('Invalid post code'),
  Unauthorized: t('Not authorized'),
  SignUpFailed: t('Sign up failed'),
  ChargebeeRequestFailed: t('Chargebee Request Failed'),

  InternalServerError: t('Internal Server Error'),
  Frontend_NetworkError: t('Network error'),

  UserWithThisEmailIsAlreadyRegisteredInChargebee: t('User with this email already registered'),
  PaymentSourceIsInUse: t('This payment information is use. '),
  DeletePaymentSourceConflict: t('Unable to delete payment source.'),

  ChargebeeInvalidRequestError: t('Chargebee invalid request params'),
  ChargebeeOperationFailedError: t('Chargebee operation failed'),
  ChargebeePaymentError: t('Chargebee payment error'),
  ChargebeeSignUpFailed: t('Chargebee signup failed'),
  CognitoLogInFailed: t('Cognito login failed'),
  CognitoSignUpFailed: t('Cognito sign-up failed'),
  CreateChargebeePaymentSourceFailed: t('Create chargebee payment source failed'),
  CreateChargebeeSubscriptionFailed: t('Create chargebee subscription failed'),

  OrderResponseFailed: t('Order Response Failed'),
  ResourceInUseException: t('Resource in use'),
  ResourceNotFoundException: t('Resource not found'),

  CognitoException: t('Cognito Error'),
  EntityNotExist: t('Entity not exist'),
  ItemTypeNotProvided: t('Type not provided'),
  UserNotFound: t('User not found'),
  InvalidParams: t('Invalid params'),
  InvalidSetupIntent: t('Invalid Setup Indent'),
  EntityNotFound: t('Entity Not Found'),
  AddonAddingError: t('Sorry, we couldn’t add that. Please check and try again.'),
  AddonDeletingError: t('Addon Deleting Error'),
  AvailabilityTimeExceeded: t('Availability Time Exceeded'),
  InvalidItemPrice: t('Invalid Item Price'),
  ItemPriceAlreadyInSubscription: t(
    'Looks like you’ve added more than the maximum portions for this add-on. Please check and try again.'
  ),
  ItemPriceNotInSubscription: t('Item Price Not In Subscription'),
  LimitExceeded: t('Looks like you’ve added more than the maximum portions for this add-on. Please check and try again.'),
  SalesConfigDefinitionError: t('Sales Config Definition Error'),
  SalesConfigNotFound: t('Sales Config Not Found'),
  StockReductionError: t('Stock Reduction Error'),
  AddonUpdatingError: t('Update Addon Error'),
  CognitoRefreshFailed: t('Cognito Refresh Failed'),
  InvalidBillingAddress: t('Invalid Billing Address'),
  InvalidCognitoToken: t('Invalid Cognito Token'),
  InvalidPayment: t('Invalid Payment'),
  // InvalidPaymentMethod: t('You need to provide your payment details to reactivate subscription'),
  InvalidPaymentParams: t('Invalid Payment Params'),
  InvalidShippingAddress: t('Invalid Delivery Address'),
  SignUpBrokenDependency: t('SignUp Broken Dependency'),

  InvalidBoxItemPrice: t('Invalid Box Item Price'),
  StartDateInPast: t('Start Date In Past'),
  SubscriptionCreationError: t('Subscription Creation Error'),
  SubscriptionUpdatingError: t('Subscription Updating Error'),
  UnableToChangeStartDate: t('Unable To Change Start Date'),
  UnsupportedSubscriptionStatus: t('Unsupported Subscription Status'),
  UpdatingSubscriptionIsBlocked: t('Updating Subscription Is Blocked'),
  DeliveryConfigurationNotFoundInSubscription: '',
  UnavailableSalesConfig: t('Unavailable Sales Config'),
  FPCognitoException: t('FP Cognito Exception'),
  CognitoInvalidUsernameException: t('Cognito Invalid Username Exception'),
  CognitoEmailVerificationException: t('Cognito Email Verification Exception'),
  UnprocessedSubscriptionStatus: t('Unprocessed Subscription Status'),
  UnableToDefineDeliveryPeriod: t('Unable To Define Delivery Period'),
  ExclusionCacheError: t('ExclusionCacheError'),
  ExclusionNotFound: t('ExclusionNotFound'),
  LimitExceededException: t('LimitExceededException'),
  PauseCreationError: t('Pause Creation Error'),
  PauseNotFound: t('Pause Not Found'),
  ForcePasswordChangeFailed: t('Force Password Change Failed'),
  ForcePasswordChangeNotRequired: t('Force Password Change Not Required'),
  PasswordChangeRequired: t('PasswordChangeRequired'),
  CustomerDoesNotHaveAnySubscriptions: t('CustomerDoesNotHaveAnySubscriptions'),
  PostcodeNotConnectedToDC: t('PostcodeNotConnectedToDC'),
  GlobalLimitExceeded: t(
    'Turns out there is such a thing as “too many” add-ons in your box. You’ll need to remove one before you can add another.'
  ),
  ResourceNotFound: t('ResourceNotFound'),
  CustomerNotAuthorizedWithPassword: t(
    "You're registered with your Service_name account, please click 'Login with Google/Facebook'"
  ),

  ForgotPasswordNotAvailableForSSOUser: t('ForgotPasswordNotAvailableForSSOUser'),
  ChargebeeRequestFailedError: t('ChargebeeRequestFailedError'),
  CouponPromoCodeErrorResponse: t('CouponPromoCodeErrorResponse'),
  PauseV2Conflict: t('PauseV2Conflict'),
  PauseAlreadyAppliedError: t('PauseAlreadyAppliedError'),
  ForceChangePasswordConflict: t('ForceChangePasswordConflict'),
  ChargebeeRequestWithPaymentFailed: t('ChargebeeRequestWithPaymentFailed'),
  CustomerDataUpdateConflict: t('ChargebeeRequestWithPaymentFailed'),
  UpdateEmailError: t('UpdateEmailError'),
  UnconfirmedUserCannotChangeEmail: 'UnconfirmedUserCannotChangeEmail',
  InternalUserNotFound: t('InternalUserNotFound'),
  InvalidPasswordException: t('InvalidPasswordException'),
  OperationBlockedDueToOperationCutoff: t('OperationBlockedDueToOperationCutoff'),
  PauseDuplicatesExistingOne: t('PauseDuplicatesExistingOne'),
  PauseV2DeletionConflict: t('PauseV2DeletionConflict'),
  ResumeDateShouldBeGreaterThatPauseDate: t('ResumeDateShouldBeGreaterThatPauseDate'),
  SubscriptionCancelationError: t('SubscriptionCancelationError'),
  TokenDecryptionError: t('TokenDecryptionError'),
  CouponError: 'CouponError',
  CouponExpired: t('Coupon expired'),
  CouponNotApplicable: t('Coupon not applicable'),
  CouponNotFound: t('Invalid Coupon'),
  UpdateEmailOperationBlockedForSSOUser: 'UpdateEmailOperationBlockedForSSOUser',
  UserSuspended: 'UserSuspended',
  CustomerNotFound: 'CustomerNotFound',
  CustomerSuspended: 'CustomerSuspended',
  CustomerVerificationError: 'CustomerVerificationError',
  CustomerWithoutPaymentMethod: 'CustomerWithoutPaymentMethod',
  InvalidBox: 'InvalidBox',
  InvalidDeliveryConfiguration: 'InvalidDeliveryConfiguration',
  NotCancelledSubscriptionCannotBeReactivated: 'NotCancelledSubscriptionCannotBeReactivated',
  SubscriptionHasUnpaidInvoices: 'SubscriptionHasUnpaidInvoices',
  SubscriptionReactivationError: 'SubscriptionReactivationError',
  CouponRequestFailed: 'CouponRequestFailed',
  CouponApplyingBlocked: 'CouponApplyingBlocked',
  // EmailAddressNotValid: t('EmailAddressNotValid'),
  // GetMarketingPreferencesError: t('GetMarketingPreferencesError'),
  // TooManyCustomersWithSameEmails: t('Too many customers with same emails'),
  // UserEmailNotFound: t('UserEmailNotFound'),
  OperationBlockedDueToPaymentProcessing: t(
    'Unable to cancel due to your payment being processed, please try again later.'
  ),
  UnavailablePriceFrequencyError: t('Unavailable Price Frequency Error'),
  ApplyCouponConflictError: t('Apply Coupon Conflict Error'),
  ApplyCouponInvalidRequestParams: 'ApplyCouponInvalidRequestParams',
  SubscriptionAlreadyDiscounted: t('Subscription Already Discounted'),
  SubscriptionNotFound: t('Subscription Not Found'),
  DeliveryConfigurationNotDefined: 'Delivery Configuration Not Defined',
  SubscriptionReactivationFailed: 'Subscription Reactivation Failed',
  DeliveryDayOfTheWeekDoesNotMatchDC: 'Delivery day ot the week does not match. Refresh your browser please',
  DeliveryTimeDoesNotMatchDC: 'Delivery time does not match. Refresh your browser please',
  BoxCustomisationConfigInvalidParams: 'BoxCustomisationConfigInvalidParams',
  BoxCustomisationConfigNotFound: 'BoxCustomisationConfigNotFound',
  BoxCustomisationProduceNotFound: 'BoxCustomisationProduceNotFound',
  SubscriptionCustomiseBoxConflict: 'SubscriptionCustomiseBoxConflict',
  SubscriptionIsNotCustomisable: 'SubscriptionIsNotCustomisable',
  CutoffTimePassed: 'Your cut-off time has passed, please try again later',
  BoxCustomisationIsNotAvailable: 'Box customisation is not available',
  InvalidQueryParams: 'Invalid query params',
  ProductNotFound: 'Product not found',
  InvalidDeliveryWeekday: 'Invalid delivery weekday',
  BoxCustomisationNotFoundError: 'Box \\',
  DeliveryConfigurationNotFound: '',
  BoxCustomisationConflictError: '',
  PauseStartDateCutoffExceeded: 'Pause Start Date Cutoff Exceeded',
  PlanNotApplicable: 'Your juicy offer is waiting. Just swap your plan.',
  AddonsNotApplicable: "Your juicy offer is waiting. Just pick one of this week's add-ons.",
  PlanAndAddonsNotApplicable: "Your juicy offer is waiting. Just swap your plan or pick one of this week's add- ons.",
  BrazeError: '',
  DatabaseError: ''
};
